import "@fontsource/roboto";
import "./src/styles/foundation/variable.css";
import "./src/styles/foundation/normalize.css";
import "./src/styles/foundation/base.css";
import "./src/styles/foundation/blog-contents-style.css";


export const onInitialClientRender = () => {
  window.__GATSBY_INITIAL_RENDER_COMPLETE__ = false; // 初回レンダリング前フラグ
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation) {
    // ページ遷移時はローディング画面を表示しない
    window.__GATSBY_INITIAL_RENDER_COMPLETE__ = true;
  }
};